import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../Link"

const TeacherItem = ({ teacher }) => {
  const {
    name,
    slug,
    type,
    image: { gatsbyImageData, title: alt },
  } = teacher
  return (
    <Link to={`/teacher/${slug}`} style="flex gap-5 group">
      <GatsbyImage
        image={gatsbyImageData}
        alt={`${alt} - 言果學習講師個人形象照`}
        className="h-[76px] w-[76px] sm:flex-shrink-0 object-cover -z-10 rounded-full lg:my-auto"
      />
      <div className="flex flex-col justify-center">
        <div>
          <p className="text-xl whitespace-nowrap !leading-normal font-bold text-neutral-800 pb-1 ease-in-out duration-300 group-hover:text-amber-400 sm:text-2xl">
            {name}
          </p>
          <p className="leading-loose text-neutral-600 sm:text-lg">{type}</p>
        </div>
      </div>
    </Link>
  )
}

TeacherItem.propTypes = {
  teacher: PropTypes.object,
}

export default TeacherItem
